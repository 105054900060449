import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import avisInlusive from "../../avis_inclusive_country.json";
import { format } from "date-fns";
import axios from "axios";
import { differenceInDays } from "date-fns";
import { API_URL, BRAND } from "../../core/constant.js";

const CC = (props) => {
  const navigate = useNavigate();

  // General State
  const [carPreviews, setCarPreviews] = useState({});
  const [carsAwdInputted, setCarsAwdInputted] = useState([]);
  const [listCarData, setListCarData] = useState([]);
  const [rateCode, setRateCode] = useState("");
  const [messageResult, setMessageResult] = useState(
    "Finding recommended cars for you..."
  );
  const [totalWithBuffer, setTotalWithBuffer] = useState("");

  const [transactionId, setTransactionId] = useState("");
  const [carsCatalogData, setCarsCatalogData] = useState();

  // Pickup State
  const [pickupCodeLocation, setPickupCodeLocation] = useState("");
  const [pickupNameLocation, setPickupNameLocation] = useState("");
  const [pickupCountryCode, setPickupCountryCode] = useState("");
  const [pickupTelephoneLocation, setPickupTelephoneLocation] = useState("");
  const [pickupAddress1Location, setPickupAddress1Location] = useState("");
  const [pickupAddress2Location, setPickupAddress2Location] = useState("");
  const [pickupCityLocation, setPickupCityLocation] = useState("");
  const [pickupStateLocation, setPickupStateLocation] = useState("");
  const [pickupPostalCodeLocation, setPickupPostalCodeLocation] = useState("");

  // DropoffState
  const [dropoffCodeLocation, setDropoffCodeLocation] = useState("");
  const [dropoffNameLocation, setDropoffNameLocation] = useState("");
  const [dropoffCountryCode, setDropoffCountryCode] = useState("");
  const [dropoffTelephoneLocation, setDropoffTelephoneLocation] = useState("");
  const [dropoffAddress1Location, setDropoffAddress1Location] = useState("");
  const [dropoffAddress2Location, setDropoffAddress2Location] = useState("");
  const [dropoffCityLocation, setDropoffCityLocation] = useState("");
  const [dropoffStateLocation, setDropoffStateLocation] = useState("");
  const [dropoffPostalCodeLocation, setDropoffPostalCodeLocation] =
    useState("");

  const myLinkDis = {
    color: "rgb(168, 168, 168)",
  };

  const myBorderDis = {
    borderBottom: "2px solid rgb(168, 168, 168)",
  };

  const myLink = {
    color: "rgb(212, 0, 42)",
    cursor: "pointer",
  };

  const myBorder = {
    borderBottom: "2px solid rgb(212, 0, 42)",
  };

  const btnLink = {
    backgroundColor: "rgb(212, 0, 42)",
    color: "rgb(255, 255, 255)",
    padding: "0px 50px",
    width: "45%",
  };

  const calculateTotalCost = async (
    productType,
    pickupDate,
    dropoffDate,
    baseCost
  ) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/admin/buffer/calculate`,
        {
          params: {
            productType: productType,
            pickup_date: pickupDate,
            dropoff_date: dropoffDate,
            base_cost: baseCost,
          },
        }
      );

      if (response.data.success) {
        return response.data.data.total_cost_with_buffer;
      } else {
        setMessageResult(response.data.message);
        return null;
      }
    } catch (error) {
      console.error("Failed to calculate total cost:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCarsCatalog = async () => {
      const {
        pickupDate,
        dropoffDate,
        countryPick,
        awdNumber,
        wizardNumber,
        pickupLocation,
        dropoffLocation,
        countryCode,
        couponCode,
        age,
      } = props.before;

      const differenceDays = differenceInDays(dropoffDate, pickupDate);
      const findCountry = avisInlusive.find(
        (item) => item.country_code === countryPick
      );

      const bebe = localStorage.getItem("bebe");

      try {
        let response;
        if (
          findCountry !== undefined &&
          differenceDays >= 1 &&
          differenceDays <= 13 &&
          awdNumber === ""
        ) {
          response = await axios.get(
            "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
            {
              params: {
                brand: BRAND,
                pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
                pickup_location: pickupLocation.MNE,
                membership_code: wizardNumber,
                dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
                dropoff_location: dropoffLocation.MNE,
                country_code: countryCode,
                discount_code: "I017600",
                coupon_code: couponCode,
                // age: age,
              },
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: "04e19f27",
              },
            }
          );
        } else if (
          findCountry !== undefined &&
          differenceDays >= 14 &&
          differenceDays <= 27 &&
          awdNumber === ""
        ) {
          response = await axios.get(
            "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
            {
              params: {
                brand: BRAND,
                pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
                pickup_location: pickupLocation.MNE,
                membership_code: wizardNumber,
                dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
                dropoff_location: dropoffLocation.MNE,
                country_code: countryCode,
                discount_code: "I068100",
                coupon_code: couponCode,
              },
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: "04e19f27",
              },
            }
          );
        } else {
          response = await axios.get(
            "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
            {
              params: {
                brand: BRAND,
                pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
                pickup_location: pickupLocation.MNE,
                membership_code: wizardNumber,
                dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
                dropoff_location: dropoffLocation.MNE,
                country_code: countryCode,
                discount_code: awdNumber,
                coupon_code: couponCode,
              },
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + bebe,
                client_id: "04e19f27",
              },
            }
          );
        }

        setCarsCatalogData(response.data);
        const vehicles = response.data.vehicles;
        const vehiclesWithCost = await Promise.all(
          vehicles.map(async (vehicle) => {
            const totalCostWithBuffer =
              vehicle.rate_totals.pay_later.reservation_total;
            // const totalCostWithBuffer = await calculateTotalCost(
            //   BRAND,
            //   format(pickupDate, "yyyy-MM-dd"),
            //   format(dropoffDate, "yyyy-MM-dd"),
            //   baseCost
            // );
            return {
              ...vehicle,
              totalCostWithBuffer,
            };
          })
        );

        setCarsAwdInputted(
          vehiclesWithCost.sort(
            (a, b) => a.totalCostWithBuffer - b.totalCostWithBuffer
          )
        );
      } catch (error) {
        setMessageResult("No cars available for your search criteria.");

        if (error.response?.status === 401) {
          setMessageResult(error.response.data.status.errors[0].details);
        }

        if (error.response?.status === 400) {
          setMessageResult(error.response.data.status.errors[0].details);
        }
      }
    };

    fetchCarsCatalog();
  }, [props.before]);

  // useEffect(() => {
  //   const fetchCarsCatalog = async () => {
  //     const {
  //       pickupDate,
  //       dropoffDate,
  //       countryPick,
  //       awdNumber,
  //       wizardNumber,
  //       pickupLocation,
  //       dropoffLocation,
  //       countryCode,
  //       couponCode,
  //       discountCode,
  //     } = props.before;

  //     const differenceDays = differenceInDays(dropoffDate, pickupDate);
  //     const findCountry = avisInlusive.find(
  //       (item) => item.country_code === countryPick
  //     );

  //     const bebe = localStorage.getItem("bebe");

  //     try {
  //       if (
  //         findCountry !== undefined &&
  //         differenceDays >= 1 &&
  //         differenceDays <= 13 &&
  //         awdNumber === ""
  //       ) {
  //         // I017600
  //         const response = await axios.get(
  //           "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
  //           {
  //             params: {
  //               brand: BRAND,
  //               pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               pickup_location: pickupLocation.MNE,
  //               membership_code: wizardNumber,
  //               dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               dropoff_location: dropoffLocation.MNE,
  //               country_code: countryCode,
  //               discount_code: "I017600",
  //               coupon_code: couponCode,
  //             },
  //             headers: {
  //               Accept: "*/*",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + localStorage.getItem("bebe"),
  //               client_id: "04e19f27",
  //             },
  //           }
  //         );

  //         setCarsCatalogData(response.data);
  //         setCarsAwdInputted(
  //           response.data.vehicles.sort((a, b) => {
  //             return (
  //               a.rate_totals.pay_later.reservation_total -
  //               b.rate_totals.pay_later.reservation_total
  //             );
  //           })
  //         );
  //       } else if (
  //         findCountry !== undefined &&
  //         differenceDays >= 14 &&
  //         differenceDays <= 27 &&
  //         awdNumber === ""
  //       ) {
  //         console.log("2");
  //         // I068100
  //         const response = await axios.get(
  //           "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
  //           {
  //             params: {
  //               brand: BRAND,
  //               pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               pickup_location: pickupLocation.MNE,
  //               membership_code: wizardNumber,
  //               dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               dropoff_location: dropoffLocation.MNE,
  //               country_code: countryCode,
  //               discount_code: "I017600",
  //               coupon_code: couponCode,
  //             },
  //             headers: {
  //               Accept: "*/*",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + localStorage.getItem("bebe"),
  //               client_id: "04e19f27",
  //             },
  //           }
  //         );

  //         setCarsCatalogData(response.data);
  //         setCarsAwdInputted(
  //           response.data.vehicles.sort((a, b) => {
  //             return (
  //               a.rate_totals.pay_later.reservation_total -
  //               b.rate_totals.pay_later.reservation_total
  //             );
  //           })
  //         );
  //       } else {
  //         const response = await axios.get(
  //           "https://stage.abgapiservices.com/cars/catalog/v2/vehicles",
  //           {
  //             params: {
  //               brand: BRAND,
  //               pickup_date: format(pickupDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               pickup_location: pickupLocation.MNE,
  //               membership_code: wizardNumber,
  //               dropoff_date: format(dropoffDate, "yyyy-MM-dd'T'HH:mm:ss"),
  //               dropoff_location: dropoffLocation.MNE,
  //               country_code: countryCode,
  //               discount_code: awdNumber,
  //               coupon_code: couponCode,
  //             },
  //             headers: {
  //               Accept: "*/*",
  //               "Content-Type": "application/json",
  //               Authorization: "Bearer " + bebe,
  //               client_id: "04e19f27",
  //             },
  //           }
  //         );

  //         setCarsCatalogData(response.data);
  //         setListCarData(
  //           response.data.vehicles.sort((a, b) => {
  //             return (
  //               a.rate_totals.pay_later.reservation_total -
  //               b.rate_totals.pay_later.reservation_total
  //             );
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       setMessageResult("No cars available for your search criteria.");

  //       if (error.response?.status === 401) {
  //         // window.location.reload();
  //       }

  //       if (error.response?.status === 400) {
  //         setMessageResult(error.response.data.status.errors[0].details);
  //       }
  //     }
  //   };

  //   const calculateTotalCost = async (
  //     productType,
  //     pickupDate,
  //     dropoffDate,
  //     baseCost
  //   ) => {
  //     try {
  //       const response = await axios.get(
  //         `${API_URL}/api/admin/buffer/calculate`,
  //         {
  //           params: {
  //             productType: productType,
  //             pickup_date: pickupDate,
  //             dropoff_date: dropoffDate,
  //             base_cost: baseCost,
  //           },
  //         }
  //       );

  //       if (response.data.success) {
  //         setTotalWithBuffer(response.data.data.total_cost_with_buffer);
  //       } else {
  //         setMessageResult(response.data.message);
  //       }
  //     } catch (error) {
  //       return null;
  //     }
  //   };

  //   fetchCarsCatalog();
  // }, [props.before]);

  const handleTogglePreviewFeature = (index) => {
    setCarPreviews((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleChoose = (vehicle, cars, total, image, name, model, make) => {
    navigate("/extra", {
      state: {
        transactionId: transactionId,
        pickupCodeLocation: pickupCodeLocation,
        dropoffCodeLocation: dropoffCodeLocation,
        pickupNameLocation: pickupNameLocation,
        dropoffNameLocation: dropoffNameLocation,
        pickupCountryCode: pickupCountryCode,
        dropoffCountryCode: dropoffCountryCode,
        pickupTelephoneLocation: pickupTelephoneLocation,
        dropoffTelephoneLocation: dropoffTelephoneLocation,
        pickupAddress1Location: pickupAddress1Location,
        dropoffAddress1Location: dropoffAddress1Location,
        pickupAddress2Location: pickupAddress2Location,
        dropoffAddress2Location: dropoffAddress2Location,
        pickupCityLocation: pickupCityLocation,
        dropoffCityLocation: dropoffCityLocation,
        pickupStateLocation: pickupStateLocation,
        dropoffStateLocation: dropoffStateLocation,
        pickupPostalCodeLocation: pickupPostalCodeLocation,
        dropoffPostalCodeLocation: dropoffPostalCodeLocation,
        pickupCoordinate: props.before.pickupCoordinate,
        dropoffCoordinate: props.before.dropoffCoordinate,
        rateCode: rateCode,
        awdNumber: props.before.awdNumber,
        couponCode: props.before.couponCode,
        pickupLocation: props.before.pickupLocation,
        pickupDate: props.before.pickupDate,
        pickupTime: props.before.pickupTime,
        dropoffLocation: props.before.dropoffLocation,
        dropoffDate: props.before.dropoffDate,
        dropoffTime: props.before.dropoffTime,
        age: props.before.age,
        country: props.before.countryPick,
        vehicle: vehicle,
        cars: cars,
        total: total,
        image: image,
        name: name,
        model: model,
        make: make,
      },
    });
  };

  useEffect(() => {
    if (carsCatalogData) {
      // General
      setTransactionId(carsCatalogData.transaction.transaction_id);

      // Pickup
      setPickupCodeLocation(
        carsCatalogData.reservation.pickup_location?.location?.code
      );
      setPickupNameLocation(
        carsCatalogData.reservation.pickup_location?.location?.name
      );
      setPickupCountryCode(
        carsCatalogData.reservation.pickup_location?.address?.country_code
      );
      setPickupTelephoneLocation(
        carsCatalogData.reservation.pickup_location?.location?.telephone
      );
      setPickupAddress1Location(
        carsCatalogData.reservation.pickup_location?.address?.address_line_1
      );
      setPickupAddress2Location(
        carsCatalogData.reservation.pickup_location?.address?.address_line_2
      );
      setPickupCityLocation(
        carsCatalogData.reservation.pickup_location?.address?.city
      );
      setPickupStateLocation(
        carsCatalogData.reservation.pickup_location?.address?.state_name
      );
      setPickupPostalCodeLocation(
        carsCatalogData.reservation.pickup_location?.address?.postal_code
      );

      // Dropoff
      setDropoffCodeLocation(
        carsCatalogData.reservation.pickup_location?.location?.code
      );
      setDropoffNameLocation(
        carsCatalogData.reservation.dropoff_location?.location?.name
      );
      setDropoffCountryCode(
        carsCatalogData.reservation.dropoff_location?.address?.country_code
      );
      setDropoffTelephoneLocation(
        carsCatalogData.reservation.dropoff_location?.location?.telephone
      );
      setDropoffAddress1Location(
        carsCatalogData.reservation.dropoff_location?.address?.address_line_1
      );
      setDropoffAddress2Location(
        carsCatalogData.reservation.dropoff_location?.address?.address_line_2
      );
      setDropoffCityLocation(
        carsCatalogData.reservation.dropoff_location?.address?.city
      );
      setDropoffStateLocation(
        carsCatalogData.reservation.dropoff_location?.address?.state_name
      );
      setDropoffPostalCodeLocation(
        carsCatalogData.reservation.dropoff_location?.address?.postal_code
      );
    }
  }, [carsCatalogData]);

  const handleSort = (value) => {
    let carsWantSort =
      carsAwdInputted.length === 0 ? listCarData : carsAwdInputted;

    const sortedCars = [...carsWantSort];

    if (value === "lowHigh") {
      sortedCars.sort((a, b) => {
        return (
          a.rate_totals.pay_later.reservation_total -
          b.rate_totals.pay_later.reservation_total
        );
      });
    } else {
      sortedCars.sort((a, b) => {
        return (
          b.rate_totals.pay_later.reservation_total -
          a.rate_totals.pay_later.reservation_total
        );
      });
    }

    if (carsAwdInputted.length === 0) {
      setListCarData(sortedCars);
    } else {
      setCarsAwdInputted(sortedCars);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <p style={myLinkDis}>1</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLink}>2 Select a Car</p>
              <p style={myBorder}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>3</p>
              <p style={myBorderDis}></p>
            </div>
            <div className="col-sm-3">
              <p style={myLinkDis}>4</p>
              <p style={myBorderDis}></p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-6 col-lg-4">
              <p style={myLink}>
                <b>Pick-Up</b>
              </p>
              <p>
                <b>{pickupNameLocation}</b>
                <br />
                {pickupCityLocation},{pickupCodeLocation}
                <br />
                {pickupAddress1Location}
                {pickupAddress2Location},{pickupPostalCodeLocation},
                <br />
                <b>{pickupTelephoneLocation}</b>
                <br />
                <b>Location Code: </b> {pickupCodeLocation}
              </p>
              <p>
                <b>{format(props.before.pickupDate, "HH:mm a MMMM d, yyyy")}</b>
              </p>
            </div>
            <div className="col-sm-6 col-lg-4 border-start">
              <p style={myLink}>
                <b>Return</b>
              </p>
              <p>
                <b>{dropoffNameLocation}</b>
                <br />
                {dropoffCityLocation},{dropoffCodeLocation}
                <br />
                {dropoffAddress1Location}
                {dropoffAddress2Location},{dropoffPostalCodeLocation},
                <br />
                <b>{dropoffTelephoneLocation}</b>
                <br />
                <b>Location Code: </b> {dropoffCodeLocation}
              </p>
              <p>
                <b>
                  {format(props.before.dropoffDate, "HH:mm a MMMM d, yyyy")}
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-sm-12 col-lg-12">
              <hr></hr>
            </div>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#eaeaea" }}>
        <div className="container">
          <div className="row pt-2 align-items-center">
            <div className="col-lg-3">
              <div className="input-group mb-2">
                <span className="input-group-text" id="basic-addon2">
                  Sort by:
                </span>
                <select
                  className="form-control"
                  onChange={(e) => handleSort(e.target.value)}
                >
                  <option value={"lowHigh"}>Low to High</option>
                  <option value={"highLow"}>High to Low</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          {carsAwdInputted && carsAwdInputted?.length > 0 ? (
            carsAwdInputted?.map((ic, i) => (
              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-3" style={{ cursor: "pointer" }}>
                      {carPreviews && carPreviews[i] ? (
                        <div
                          className="row justify-content-between"
                          onClick={() => handleTogglePreviewFeature(i)}
                        >
                          <div className="col-sm-6">
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-door-open"></i>{" "}
                              {ic.capacity?.doors} Doors
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-wind"></i>{" "}
                              {ic.features.air_conditioned === true
                                ? "Air Conditioning"
                                : ""}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-gas-pump"></i> {ic.capacity?.mpg}{" "}
                              mpg
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-suitcase-rolling"></i>{" "}
                              {ic.capacity?.luggage_capacity?.large_suitcase}{" "}
                              Large Bags
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <p style={{ fontSize: "13px" }}>
                              <i className="fas fa-user"></i>{" "}
                              {ic.capacity?.seats} Seats
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-car"></i>{" "}
                              {ic.category?.vehicle_transmission}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-suitcase"></i>{" "}
                              {ic.capacity?.luggage_capacity?.small_suitcase}{" "}
                              Small Bags
                            </p>
                          </div>
                        </div>
                      ) : (
                        <img
                          alt={ic.category.small_image_url}
                          src={ic.category.small_image_url}
                          className="img-fluid"
                          onClick={() => handleTogglePreviewFeature(i)}
                        />
                      )}
                    </div>
                    <div className="col-sm-3">
                      <p>
                        <h4 className="text-dark">{ic.category.name}</h4>
                      </p>
                      <p>
                        {ic.category.make} {ic.category.model}
                      </p>
                      <p className="text-black">
                        <img src="/assets/images/icon/user.png" alt="user" />{" "}
                        {ic.capacity.seats}
                        <img
                          alt="suitcase"
                          src="/assets/images/icon/suitcase.png"
                          className="ms-2"
                        />{" "}
                        {ic.capacity?.luggage_capacity?.large_suitcase}L
                        <img
                          alt="suitcase"
                          src="/assets/images/icon/suitcase.png"
                          className="ms-2"
                        />{" "}
                        {ic.capacity?.luggage_capacity?.small_suitcase}S
                      </p>
                      <p>{ic.category.vehicle_transmission}</p>
                      <p
                        onClick={() => handleTogglePreviewFeature(i)}
                        style={myLink}
                      >
                        {carPreviews && carPreviews[i]
                          ? "Close Features"
                          : "See More Features"}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <div className="row justify-content-between">
                        <div className="col-sm-5"></div>
                        <div className="col-sm-5">
                          <h4 className="text-dark">
                            {ic.rate_totals.rate?.currency}{" "}
                            {ic.totalCostWithBuffer.toFixed(2)}
                          </h4>
                        </div>
                        <div className="col-sm-1">
                          <input
                            type="radio"
                            name="rate_code"
                            className="form-check-input"
                            value={ic.rate_totals.rate.rate_code}
                            onChange={(e) => setRateCode(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row justify-content-end mt-5">
                        <button
                          className="btn py-2"
                          style={{ ...btnLink }}
                          onClick={() =>
                            handleChoose(
                              ic,
                              ic.rate_totals.pay_later.sipp_code,
                              ic.rate_totals.pay_later.reservation_total,
                              ic.category.small_image_url,
                              ic.category.name,
                              ic.category.model,
                              ic.category.make
                            )
                          }
                        >
                          Choose
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <hr></hr>
                </div>
              </div>
            ))
          ) : listCarData && listCarData?.length > 0 ? (
            listCarData?.map((ic, i) => (
              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-3" style={{ cursor: "pointer" }}>
                      {carPreviews && carPreviews[i] ? (
                        <div
                          className="row justify-content-between"
                          onClick={() => handleTogglePreviewFeature(i)}
                        >
                          <div className="col-sm-6">
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-door-open"></i>{" "}
                              {ic.capacity.doors} Doors
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-wind"></i>{" "}
                              {ic.features.air_conditioned === true
                                ? "Air Conditioning"
                                : ""}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-gas-pump"></i> {ic.capacity.mpg}{" "}
                              mpg
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-suitcase-rolling"></i>{" "}
                              {ic.capacity?.luggage_capacity?.large_suitcase}{" "}
                              Large Bags
                            </p>
                          </div>
                          <div className="col-sm-6">
                            <p style={{ fontSize: "13px" }}>
                              <i className="fas fa-user"></i>{" "}
                              {ic.capacity?.seats} Seats
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-car"></i>{" "}
                              {ic.category?.vehicle_transmission}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <i class="fas fa-suitcase"></i>{" "}
                              {ic.capacity?.luggage_capacity?.small_suitcase}{" "}
                              Small Bags
                            </p>
                          </div>
                        </div>
                      ) : (
                        <img
                          alt={ic.category.small_image_url}
                          src={ic.category.small_image_url}
                          className="img-fluid"
                          onClick={() => handleTogglePreviewFeature(i)}
                        />
                      )}
                    </div>
                    <div className="col-sm-3">
                      <p>
                        <h4 className="text-dark">{ic.category.name}</h4>
                      </p>
                      <p>
                        {ic.category.make} {ic.category.model}
                      </p>
                      <p className="text-black">
                        <img src="/assets/images/icon/user.png" alt="user" />{" "}
                        {ic.capacity.seats}
                        <img
                          alt="suitcase"
                          src="/assets/images/icon/suitcase.png"
                          className="ms-2"
                        />{" "}
                        {ic.capacity?.luggage_capacity?.large_suitcase}L
                        <img
                          alt="suitcase"
                          src="/assets/images/icon/suitcase.png"
                          className="ms-2"
                        />{" "}
                        {ic.capacity?.luggage_capacity?.small_suitcase}S
                      </p>
                      <p>{ic.category.vehicle_transmission}</p>
                      <p
                        onClick={() => handleTogglePreviewFeature(i)}
                        style={myLink}
                      >
                        {carPreviews && carPreviews[i]
                          ? "Close Features"
                          : "See More Features"}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      {props.before.awd_number !== "" ? (
                        <div className="row justify-content-between">
                          <div className="col-sm-5"></div>
                          <div className="col-sm-5">
                            <h4 className="text-dark">
                              {ic.rate_totals.rate?.currency}{" "}
                              {ic.rate_totals.pay_later.reservation_total.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                          <div className="col-sm-1">
                            <input
                              type="radio"
                              name="rate_code"
                              className="form-check-input"
                              value={ic.rate_totals.rate.rate_code}
                              onChange={(e) => setRateCode(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : props.before.countryPick === "US" ? (
                        <>
                          <div className="row justify-content-between mt-4">
                            <div className="col-sm-5">
                              <h4 className="text-dark">APA All Inclusive</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {ic.rate_totals.pay_later.reservation_total.toFixed(
                                  2
                                )}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={"XX"}
                                onChange={(e) => setRateCode(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row justify-content-between mt-4">
                            <div className="col-sm-5">
                              <h4 className="text-dark">GPS Inclusive</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {ic.rate_totals.pay_later.reservation_total.toFixed(
                                  2
                                )}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={"H9"}
                                onChange={(e) => setRateCode(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : props.before.countryPick === "CA" ? (
                        <div className="row justify-content-between mt-4">
                          <div className="col-sm-5">
                            <h4 className="text-dark">APAC</h4>
                          </div>
                          <div className="col-sm-5">
                            <h4 className="text-dark">
                              {ic.rate_totals.rate?.currency}{" "}
                              {ic.rate_totals.pay_later.reservation_total.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                          <div className="col-sm-1">
                            <input
                              type="radio"
                              name="rate_code"
                              className="form-check-input"
                              value={"S6I"}
                              onChange={(e) => setRateCode(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : props.carsAwd && props.carsAwd.length > 0 ? (
                        <>
                          <div className="row justify-content-between mt-4">
                            <div className="col-sm-5">
                              <h4 className="text-dark">Avis Inclusive Rate</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {props.carsAwd && props.carsAwd.length > i
                                  ? props.carsAwd[
                                      i
                                    ].rate_totals.pay_later.reservation_total.toFixed(
                                      2
                                    )
                                  : ""}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={ic.rate_totals.rate.rate_code}
                                onChange={(e) => setRateCode(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="row justify-content-between mt-4">
                            <div className="col-sm-5">
                              <h4 className="text-dark">Basic Rate</h4>
                            </div>
                            <div className="col-sm-5">
                              <h4 className="text-dark">
                                {ic.rate_totals.rate?.currency}{" "}
                                {ic.rate_totals.pay_later.reservation_total.toFixed(
                                  2
                                )}
                              </h4>
                            </div>
                            <div className="col-sm-1">
                              <input
                                type="radio"
                                name="rate_code"
                                className="form-check-input"
                                value={ic.rate_totals.rate.rate_code}
                                onChange={(e) => setRateCode(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="row justify-content-between">
                          <div className="col-sm-5">
                            <h4 className="text-dark">Basic Rate</h4>
                          </div>
                          <div className="col-sm-5">
                            <h4 className="text-dark">
                              {ic.rate_totals.rate?.currency}{" "}
                              {ic.rate_totals.pay_later.reservation_total.toFixed(
                                2
                              )}
                            </h4>
                          </div>
                          <div className="col-sm-1">
                            <input
                              type="radio"
                              name="rate_code"
                              className="form-check-input"
                              value={ic.rate_totals.rate.rate_code}
                              onChange={(e) => setRateCode(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row justify-content-end mt-5">
                        <button
                          className="btn py-2"
                          style={{ ...btnLink }}
                          onClick={(e) =>
                            handleChoose(
                              ic,
                              ic.rate_totals.pay_later.sipp_code,
                              ic.rate_totals.pay_later.reservation_total,
                              ic.category.small_image_url,
                              ic.category.name,
                              ic.category.model,
                              ic.category.make
                            )
                          }
                        >
                          Choose
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <hr></hr>
                </div>
              </div>
            ))
          ) : (
            <div className="row mt-4 py-4">
              <div className="col-sm-12 text-center">
                <h1 style={myLink}>{messageResult}</h1>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CC;
