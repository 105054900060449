import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SignModal from "../../common/header/SignModal";
import Axios from "axios";
import { API_URL, BRAND } from "../../core/constant";
import { formatDateTglBulanJam, getToken } from "../../core/functions";
import { format } from "date-fns";
import { FaCheck, FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import FooterOne from "../../common/footer/FooterOne";

export default class vmc_view extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getReservation = (e) => {
    e.preventDefault();
    Axios.get(
      `https://stage.abgapiservices.com/cars/reservation/v2?brand=Avis&confirmation_number=${this.state.bookingCode}&last_name=${this.state.last_name}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bebe"),
          client_id: "04e19f27",
        },
      }
    )
      .then(function (response) {
        return response.data;
      })
      .then(function (data) {
        // handle success
        console.log("DATA RESPONSE: ", data);
        this.setState({ bookingData: data });
        this.setState({ isShowBooking: true });
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          getToken();
          alert("Booking not found or Please try again");
          window.location.reload();
        } else if (error.response.status == 400) {
          alert("Please try again later");
        }
      })
      .finally(function () {});
  };

  cancelBooking = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const cancelPayloadABG = {
      product: {
        brand: BRAND,
      },
      transaction: {
        transaction_id: this.props.bookingData.transaction.transaction_id,
      },
      reservation: {
        email_notification: true,
        confirmation: {
          number: this.props.bookingData.reservation.confirmation.number,
        },
      },
      customer: {
        contact: {
          last_name:
            this.props.bookingData.reservation.customer.contact.last_name,
        },
      },
    };

    const cancelPayloadBudget = {
      booking_code: this.props.bookingData.reservation.confirmation.number,
      email: this.props.bookingDataApi.emailForm,
    };

    try {
      const [abgResponse, budgetResponse] = await Promise.all([
        Axios.put(
          "https://stage.abgapiservices.com/cars/reservation/v2",
          cancelPayloadABG,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("bebe"),
              client_id: "04e19f27",
            },
          }
        ),
        Axios.post(
          "https://budget-back.aits.co.id/public/api/booking/cancel",
          cancelPayloadBudget,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
      ]);

      alert("Booking cancelled successfully!");
      console.log("ABG Cancel Response:", abgResponse.data);
      console.log("Budget Cancel Response:", budgetResponse.data);

      // Navigasi setelah kedua API berhasil dipanggil
      this.props.navigate("/view_cancel");

      this.setState({ isLoading: false });
    } catch (error) {
      console.error("Error cancelling booking:", error);
      alert("Failed to cancel booking, please try again.");
      this.setState({ isLoading: false });
    }
  };

  obfuscateName(firstName, lastName) {
    const obfuscatedFirstName = firstName;
    const obfuscatedLastName = lastName;
    return obfuscatedFirstName + " " + obfuscatedLastName;
  }

  obfuscateEmail(email) {
    const atIndex = email.indexOf("@");
    const obfuscatedEmail =
      email.substring(0, atIndex).substring(0, 3) +
      "*****" +
      email.substring(atIndex);
    return obfuscatedEmail;
  }

  obfuscateMobile(mobileNumber) {
    const obfuscatedMobile =
      mobileNumber.substring(0, 3) + "******" + mobileNumber.substring(9);
    return obfuscatedMobile;
  }

  showInclusions = (inclusions) => {
    const inclusionList = [];
    for (let index = 0; index < inclusions.length; index++) {
      inclusionList.push(
        <li key={index}>
          <span style={{ color: "rgb(212, 0, 42)" }}>&#10003;</span>{" "}
          {inclusions[index].name}
        </li>
      );
    }
    return inclusionList;
  };

  componentDidMount() {
    const fetchDetailData = async () => {};
    const fetchData = async () => {
      const pickupLocation =
        this.props.bookingData.reservation.pickup_location.location.code;
      const clientId = "04e19f27";

      if (pickupLocation) {
        try {
          const response = await Axios.get(
            `https://stage.abgapiservices.com/cars/locations/v1?keyword=${pickupLocation}&client_id=${clientId}`,
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: clientId,
              },
            }
          );

          console.log(response, "response");

          const pickupData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code ===
                this.props.bookingData.reservation.pickup_location.location.code
          );

          const dropoffData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code ===
                this.props.bookingData.reservation.dropoff_location.location
                  .code
          );

          this.setState({ pickupLat: pickupData.address.lat });
          this.setState({ pickupLng: pickupData.address.long });
          this.setState({ dropoffLat: dropoffData.address.lat });
          this.setState({ dropoffLng: dropoffData.address.long });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    const obfuscateTotal = async () => {
      const total =
        this.props.bookingData.reservation.rate_totals.totals.reservation_total;
      try {
        const fromCurrency =
          this.props.bookingData.reservation.rate_totals.rate.currency;
        const currentDate = new Date().toISOString().split("T")[0];

        const convertTotalToIdrRes = await Axios.get(
          "https://api.fxratesapi.com/convert",
          {
            params: {
              from: fromCurrency,
              to: "IDR",
              date: currentDate,
              amount: total,
              format: "json",
            },
          }
        );

        const totalIdr = Math.round(convertTotalToIdrRes.data.result);

        const formattedTotalIdr = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(totalIdr);

        this.setState({ totalsidr: formattedTotalIdr });
      } catch (error) {
        console.error("Error converting currency:", error);
        return total;
      } finally {
        console.log(this.state.totalsidr);
      }
    };

    fetchData();
    obfuscateTotal();
  }

  render() {
    if (
      this.state.currentLatitude &&
      this.state.currentLongitude &&
      this.state.pickupLat &&
      this.state.pickupLng
    ) {
      const currentLocation = `${this.state.currentLatitude},${this.state.currentLongitude}`;
      const targetLocation = `${this.state.pickupLat},${this.state.pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      this.setState({ pickupUrl: url });
    }

    if (
      this.state.currentLatitude &&
      this.state.currentLongitude &&
      this.state.dropoffLat &&
      this.state.dropoffLng
    ) {
      const currentLocation = `${this.state.currentLatitude},${this.state.currentLongitude}`;
      const targetLocation = `${this.state.dropoffLat},${this.state.dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      this.setState({ dropoffUrl: url });
    }

    const successGetCurrentLocation = (position) => {
      this.setState({ currentLatitude: position.coords.latitude });
      this.setState({ currentLongitude: position.coords.longitude });
      this.setState({ errorGetCurrentLocation: null });
    };

    const errorMessageGetCurrentLocation = (error) => {
      this.setState({ errorGetCurrentLocation: error.message });
    };

    let publicUrl = process.env.PUBLIC_URL + "/";

    const customIcon = new Icon({
      iconUrl: publicUrl + "assets/images/icon/marker.png",
      iconSize: [38, 38],
    });

    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/reservation.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const myLink = {
      color: "rgb(212, 0, 42)",
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      this.setState({ errorgetCurrentLocation: "Error" });
    }

    console.log(this.props);

    return (
      <div className="px-5">
        <section className="mt-4">
          <div className="container p-0 m-0">
            <div className="row">
              <div className="col-sm-12 text-danger">YOUR INFORMATION</div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-sm-3">Name</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  value={this.obfuscateName(
                    this.props.bookingData.reservation.customer.contact
                      .first_name,
                    this.props.bookingData.reservation.customer.contact
                      .last_name
                  )}
                  className="w-100 p-1"
                  disabled
                />
              </div>
            </div>
            {/* <div className="row d-flex align-items-center mt-2">
              <div className="col-sm-3">Email</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  value={this.obfuscateEmail(this.props.bookingData.reservation.customer.contact
                    .first_name)}
                  className="w-100 p-1"
                  disabled
                />
              </div>
            </div>
            <div className="row d-flex align-items-center mt-2">
              <div className="col-sm-3">Mobile Number</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  value={this.obfuscateMobile("081234567890")}
                  className="w-100 p-1"
                  disabled
                />
              </div>
            </div> */}
            <div className="row d-flex align-items-center mt-2">
              <div className="col-sm-3">City</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  value={
                    this.props.bookingData.reservation.customer.address.city
                  }
                  className="w-100 p-1"
                  disabled
                />
              </div>
            </div>
            <div className="row d-flex align-items-center mt-2">
              <div className="col-sm-3">Address</div>
              <div className="col-sm-9">
                <input
                  type="text"
                  value={
                    this.props.bookingData.reservation.customer.address
                      .address_line_1
                  }
                  className="w-100 p-1"
                  disabled
                />
              </div>
            </div>
          </div>
        </section>
        {/* Header */}
        <section id="car-information" className="mt-4">
          <div
            style={{ backgroundColor: "#ebe8e8" }}
            className="d-flex flex-column grid"
          >
            <div className="d-flex justify-content-between">
              <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                Your Booking
              </div>
              <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
                Booking Number :{" "}
                {this.props.bookingData.reservation.confirmation.number}
              </div>
            </div>

            <div
              style={{
                borderTop: "1px solid #d1d1d1",
                borderBottom: "1px solid #d1d1d1",
              }}
            >
              <div className="px-4">
                <div className="row">
                  <div
                    className="col-sm py-4"
                    style={{ borderRight: "1px solid #d1d1d1" }}
                  >
                    <div className="text-danger">When & Where</div>
                    <p>
                      {format(
                        new Date(
                          this.props.bookingData.reservation.confirmation.pickup_date
                        ),
                        "dd MMM yyy HH:mm"
                      )}
                      -
                      {format(
                        new Date(
                          this.props.bookingData.reservation.confirmation.dropoff_date
                        ),
                        "dd MMM yyy HH:mm"
                      )}
                    </p>
                    <p>
                      {
                        this.props.bookingData.reservation.pickup_location
                          .location.name
                      }{" "}
                      -{" "}
                      {
                        this.props.bookingData.reservation.dropoff_location
                          .location.name
                      }
                    </p>
                  </div>
                  <div
                    className="col-sm py-4"
                    style={{ borderRight: "1px solid #d1d1d1" }}
                  >
                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <p>
                          <h5 className="text-dark">
                            {
                              this.props.bookingData.reservation.vehicle
                                .category.make
                            }
                          </h5>
                        </p>
                        <p>
                          {
                            this.props.bookingData.reservation.vehicle.category
                              .make
                          }{" "}
                          {
                            this.props.bookingData.reservation.vehicle.category
                              .model
                          }
                        </p>
                        <p>
                          {
                            this.props.bookingData.reservation.vehicle.category
                              .vehicle_transmission
                          }
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-12 d-flex justify-content-center">
                      <img src={""} className="img-fluid" width={200} />
                    </div>
                  </div>
                  <div className="col-sm py-4">
                    <div className="text-danger">Extras</div>
                    <p>None Selected</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between p-4">
              <div>
                <div className="text-danger">Total</div>
                <div>{this.state.totalsidr}</div>
              </div>
              <button
                type="submit"
                className="btn btn-primary rounded-0"
                // disabled={this.state.isLoading}
                style={{
                  backgroundColor: "rgb(212, 0, 42)",
                  boxShadow: "none",
                }}
                onClick={this.cancelBooking}
              >
                {this.state.isLoading ? "Cancelling..." : "Cancel Booking"}
              </button>
            </div>
          </div>
        </section>
        {/* Information */}
        <section id="information" className="mt-4">
          <div className="px-4">
            <div className="row">
              <div className="col-sm d-flex flex-column grid gap-3">
                <div className="fs-5" style={{ fontWeight: "bold" }}>
                  When you collect your car
                </div>
                <div>
                  One of your peyser cords must be in the name of the main
                  driver unless booked on a centrally billed company credit card
                  or account and you'll need to present the following at the
                  counter:
                </div>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaCheck color="red" />
                  <div>A valid driving license</div>
                </div>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaCheck color="red" />
                  <div>1 payment card</div>
                </div>
              </div>
              <div className="col-sm d-flex flex-column grid gap-3">
                <div className="fs-5">Your reservation includes:</div>
                <div className="fs-5">
                  (Inclusions are subject to your agreed/contracted rates)
                </div>
                <div className="d-flex">
                  <div
                    className="d-flex flex-column grid gap-3"
                    style={{ marginRight: "150px" }}
                  >
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Airport subcharge</div>
                    </div>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Local tax</div>
                    </div>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Vehicle Luggage Free</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column grid gap-3">
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Theft cover</div>
                    </div>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Damage cover</div>
                    </div>
                    <div className="d-flex grid gap-3 align-items-center">
                      <FaCheck color="red" />
                      <div>Unlimited</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Rental Information */}
        <section id="rental-information" className="my-4">
          <div style={{ backgroundColor: "#ebe8e8" }} className="p-4">
            <div className="fs-5" style={{ fontWeight: "bold" }}>
              Getting to your rental station
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-sm grid gap-2 d-flex flex-column">
                  <div style={{ color: "rgb(212, 0, 42)" }}>Pick Up</div>
                  <p>
                    <b>
                      {
                        this.props.bookingData.reservation.pickup_location
                          .location.name
                      }
                    </b>
                    <br />
                    {
                      this.props.bookingData.reservation.pickup_location.address
                        .city
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.pickup_location
                        .location.code
                    }
                    <br />
                    {
                      this.props.bookingData.reservation.pickup_location.address
                        .address_line_1
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.pickup_location.address
                        .address_line_2
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.pickup_location.address
                        .postal_code
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.pickup_location.address
                        .country_code
                    }
                    <br />
                    <b>Location Code: </b>{" "}
                    {
                      this.props.bookingData.reservation.pickup_location
                        .location.code
                    }
                  </p>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaPhoneAlt color="red" />
                    <div>
                      tel:{" "}
                      {
                        this.props.bookingData.reservation.pickup_location
                          .location.telephone
                      }
                    </div>
                  </div>
                  <Link
                    to={this.state.pickupUrl}
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    Get directions
                  </Link>
                  {this.state.pickupLat && this.state.pickupLng && (
                    <div className="mt-3">
                      <MapContainer
                        center={[this.state.pickupLat, this.state.pickupLng]}
                        zoom={13}
                        scrollWheelZoom={false}
                        style={{ height: "350px" }}
                        className="w-100"
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                          icon={customIcon}
                          position={[
                            this.state.pickupLat,
                            this.state.pickupLng,
                          ]}
                        >
                          <Popup>
                            <p>
                              <b>
                                {
                                  this.props.bookingData.reservation
                                    .pickup_location.location.name
                                }
                              </b>
                              <br />
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.address.city
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.location.code
                              }
                              <br />
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.address.address_line_1
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.address.address_line_2
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.address.postal_code
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.address.country_code
                              }
                              <br />
                              <b>Location Code: </b>{" "}
                              {
                                this.props.bookingData.reservation
                                  .pickup_location.location.code
                              }
                            </p>
                          </Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  )}
                </div>
                <div className="col-sm grid gap-2 d-flex flex-column">
                  <div style={{ color: "rgb(212, 0, 42)" }}>Drop Off</div>
                  <p>
                    <b>
                      {
                        this.props.bookingData.reservation.dropoff_location
                          .location.name
                      }
                    </b>
                    <br />
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .address.city
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .location.code
                    }
                    <br />
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .address.address_line_1
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .address.address_line_2
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .address.postal_code
                    }
                    ,{" "}
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .address.country_code
                    }
                    <br />
                    <b>Location Code: </b>{" "}
                    {
                      this.props.bookingData.reservation.dropoff_location
                        .location.code
                    }
                  </p>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaPhoneAlt color="red" />
                    <div>
                      tel:{" "}
                      {
                        this.props.bookingData.reservation.dropoff_location
                          .location.telephone
                      }
                    </div>
                  </div>
                  <Link
                    to={this.state.dropoffUrl}
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    Get directions
                  </Link>
                  {this.state.dropoffLat && this.state.dropoffLng && (
                    <div className="mt-3">
                      <MapContainer
                        center={[this.state.dropoffLat, this.state.dropoffLng]}
                        zoom={13}
                        scrollWheelZoom={false}
                        style={{ height: "350px" }}
                        className="w-100"
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker
                          icon={customIcon}
                          position={[
                            this.state.dropoffLat,
                            this.state.dropoffLng,
                          ]}
                        >
                          <Popup>
                            <p>
                              <b>
                                {
                                  this.props.bookingData.reservation
                                    .dropoff_location.location.name
                                }
                              </b>
                              <br />
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.address.city
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.location.code
                              }
                              <br />
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.address.address_line_1
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.address.address_line_2
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.address.postal_code
                              }
                              ,{" "}
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.address.country_code
                              }
                              <br />
                              <b>Location Code: </b>{" "}
                              {
                                this.props.bookingData.reservation
                                  .dropoff_location.location.code
                              }
                            </p>
                          </Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="container my-5">
        <div className="card p-3">
          <h2 className="text-center">Booking Successfully</h2>
          <br />
          <h4 className="text-center">
            Here is your booking number confirmation
          </h4>
          <br />
          <div className="d-flex justify-content-center">
            <h2
              className="text-center border border-dark border-2"
              style={{ width: "250px" }}
            >
              {confirm_number}
            </h2>
          </div>

          <br />
          <div className="row justify-content-center mt-3">
            <div className="col-md-4">
              <p>
                <strong>First Name:</strong> {first_name}
              </p>
              <p>
                <strong>Last Name:</strong> {last_name}
              </p>
              <p>
                <strong>Telephone:</strong> {telephone}
              </p>
              <p>
                <strong>Email:</strong> {email}
              </p>
              <p>
                <strong>Age:</strong> {age}
              </p>
            </div>
            <div className="col-md-4">
              <p>
                <strong>Pickup Date:</strong> {pickup_date}
              </p>
              <p>
                <strong>Pickup Location:</strong> {pickup_location}
              </p>
              <p>
                <strong>Dropoff Date:</strong> {dropoff_date}
              </p>
              <p>
                <strong>Dropoff Location:</strong> {dropoff_location}
              </p>
            </div>
          </div>
          <br />
          <center>
            <a
              href={
                localStorage.getItem("token") != null
                  ? "/bookinglist"
                  : "/register"
              }
              className="btn btn-danger"
            >
              My Booking
            </a>
          </center>
        </div>
      </div> */}
      </div>
    );
  }
}
